<template>
  <div :class="$style.room" class="flex flex-column justify-between">
    <span class="text-xl">{{ roomNumber }}</span>
    <span v-line-clamp="4" :class="$style.room__name" class="text-xl" :title="name">
      {{ name }}
    </span>
  </div>
</template>

<script>
/**
 * Карточка комнаты в разделе "Список комнат"
 *
 * @vue-prop {string} roomNumber - номер комнаты
 * @vue-prop {string} name - имя комнаты
 */
export default {
  name: "SpeakerRoomsItem",
  props: {
    roomNumber: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" module>
.room {
  height: 168px;
  padding: 13px 13px 16px;
  color: $default-text-color;
  cursor: pointer;
  border: 1px solid $gray;
  border-radius: 8px;
  transition: all 0.1s linear;

  &:hover {
    color: $white;
    background-color: var(--base-color);
    border-color: transparent;
  }

  &__name {
    word-break: break-word !important;
  }
}
</style>
