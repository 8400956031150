<template>
  <speaker-rooms :loading="isLoading" :rooms="rooms" @room-change="enterToRoom" @update="init" />
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { polls, presentation, room, speaker } from "@/store/modules/store.namespaces";
import { GET_ROOMS } from "@/store/modules/speaker/action-types";
import { RESET_ROOM_STATE } from "@/store/modules/common/mutation-types";
import { RESET_PRESENTATION_STATE } from "@/store/modules/presentation/mutation-types";
import { SET_POLLCHAIN_SCORE } from "@/store/modules/polls/mutation-types";
import { CHANGE_ROOM } from "@/store/modules/common/action-types";
import { ROOM_LIMIT_MESSAGE_KEYS } from "@/constants/room/room-const";

import SpeakerRooms from "@/components/speaker/rooms/SpeakerRooms";
import { test } from "@/constants/polls/polls-module-types";
import { SpeakerRouteName } from "@/constants/router/router-const";

/**
 * Компонент представления для страницы "Список комнат"
 *
 * @vue-data {boolean} isLoading - ожидание загрузки списка
 */
export default {
  name: "ViewRooms",
  components: {
    SpeakerRooms,
  },
  props: {
    status: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState(speaker, ["rooms"]),
  },
  watch: {
    status: {
      handler(val) {
        if (val !== -1) {
          this.$nextTick(() => {
            this.$notify({
              group: "common",
              type: "error",
              title: this.$t("common.error"),
              text: this.$t(`RoomErrors.${ROOM_LIMIT_MESSAGE_KEYS[val]}`),
            });
          });
          this.$router.replace({ name: this.$route.name, query: {} });
          this.isLoading = false;
        }
      },
      immediate: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.matched[1]?.name === SpeakerRouteName.Room) {
      return next(vm => {
        vm.resetRoomState();
        vm.resetPresentationState();
        vm.resetChainScore({
          type: test,
          payload: null,
        });
      });
    }
    return next();
  },
  async created() {
    await this.init();
  },
  methods: {
    ...mapActions(speaker, {
      getRooms: GET_ROOMS,
    }),
    ...mapActions(room, {
      changeRoom: CHANGE_ROOM,
    }),
    ...mapMutations(room, {
      resetRoomState: RESET_ROOM_STATE,
    }),
    ...mapMutations(presentation, {
      resetPresentationState: RESET_PRESENTATION_STATE,
    }),
    ...mapMutations(polls, {
      resetChainScore: SET_POLLCHAIN_SCORE,
    }),
    async init() {
      try {
        if (!this.isLoading) {
          this.isLoading = true;
        }
        const rooms = await this.getRooms();
        if (rooms.length === 1) {
          this.enterToRoom(rooms[0].number);
        }
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Вход в комнату
     *
     * @param {number} roomNumber - номер комнаты
     * @returns {Promise<void>}
     */
    async enterToRoom(roomNumber) {
      this.isLoading = true;
      await this.changeRoom(roomNumber);
    },
  },
};
</script>
