<template>
  <div :class="$style.container" class="flex flex-column align-center">
    <loader v-if="loading" center />
    <template v-else>
      <h1 :class="$style.title">{{ title }}</h1>
      <div v-if="rooms.length" :class="$style.list">
        <div :class="$style.list__wrapper">
          <speaker-rooms-item
            v-for="room in rooms"
            :key="room.id"
            :room-number="room.roomNumber"
            :name="room.name"
            @click.native="roomClickHandler(room.number)"
          />
        </div>
      </div>
      <ws-button
        v-else
        :class="$style.updateBtn"
        lg
        font-size="lg"
        color="primary"
        data-testid="updateBtn"
        @click="updateBtnClickHandler"
      >
        <ws-icon lg regular>redo-alt</ws-icon>
        {{ $t("common.update") }}
      </ws-button>
    </template>
  </div>
</template>

<script>
import Loader from "@/components/common/elements/Loader";
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";
import SpeakerRoomsItem from "@/components/speaker/rooms/SpeakerRoomsItem";

/**
 * Компонент списка комнат на странице "Список комнат" на спикере
 *
 * @vue-prop {boolean} loading - ожидаем пока завершится загрузка комнат
 * @vue-prop {Array} rooms - список комнат
 * @vue-computed {string} title - текст для заголовка страницы
 * @vue-event {number}
 */
export default {
  name: "SpeakerRooms",
  components: {
    Loader,
    WsButton,
    WsIcon,
    SpeakerRoomsItem,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    rooms: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    title() {
      return this.rooms.length ? this.$t("Speaker.chooseRoom") : this.$t("Speaker.roomsListEmpty");
    },
  },
  methods: {
    updateBtnClickHandler() {
      this.$emit("update");
    },
    roomClickHandler(event) {
      this.$emit("room-change", event);
    },
  },
};
</script>

<style lang="scss" module>
.container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px 0;
  margin: 0 auto;
  overflow: hidden;
}

.updateBtn {
  width: 200px;
  min-width: auto;
  margin-top: 20px;
}

.title {
  max-width: $tablet;
  font-size: 24px;
  line-height: 27px;
  text-align: center;
}

.list {
  width: 100%;
  padding: 10px 15px 0;
  margin-top: 20px;
  overflow: auto;
  box-shadow: inset 0 13px 13px -11px $gray-light;

  @include reset-scroll;

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(290px, 330px));
    grid-gap: 10px;
    justify-content: center;
    max-width: $tablet-l;
    margin: 0 auto;
  }
}
</style>
